// carousel

.carousel {
  outline: none;
}

// carousel fade animation
.carousel-fade {
  .carousel-item {
    transition: opacity 1.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
  }

  .carousel-item.active {
    position: relative !important;
    opacity: 1;
  }
}


.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: black;
  border: 2px solid;
  border-radius: 50%;
  transition: all .1s;
}