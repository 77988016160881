@import '@core/scss/core.scss';
@import './assets/scss/styles';

body {
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    color: black !important;
  font-family: Zilla Slab;

}
#content{
  padding-left: 4rem;
  padding-right: 4rem;
}

.pl-11 {
    padding: 0rem 4rem;
}
.watermark {
  position: relative;
  bottom: 86px;
  font-size: 7rem;
  z-index: 32;
  color: #00000014;
  padding-bottom: 0px;
  font-weight: bolder;
}
.req{
  color: white;
}

.verticalCenter {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.blockblackcircle {
  top: 6px;
  height: 8px;
  width: 8px;
  // border-radius: 4px;
  // background-color: #009646;
  background-color: black;

}

.blocklightblue {
  background: rgb(49,72,127);
  background: linear-gradient(90deg, rgba(49,72,127,1) 0%, rgba(0,99,173,1) 100%);
   color: white;
   padding-bottom: 10px;
  padding-top: 7px;
}

.innerContent{
  position: absolute;
    top: 77%;
    left: 47%;
    transform: translate(-46%, -50%);
    text-align: center;
}


.main-title {
  padding-top: 6rem;
    font-weight: 600;
    font-size: 50px;
  }

  .text-content {
    // font-family: Zilla Slab;
    font-size: 21px;
    font-weight: 500;
    line-height: normal;
    text-align: justify;
    color: #393939;
    // margin-bottom: 20px;
}
.sub-content {
  font-family: Zilla Slab;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px
}


.accordion .card {
    background-color: transparent !important;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: green;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
     background-color:  #106c9d;

  }

  .modal-resource .modal-lg {
	min-width: 50% !important;
	min-width: calc(50%) !important;
  }

  // @media screen and (max-width: 1300px) {
  //   .roadmap{
  //     display: none;
  //   }
  // }
  // @media screen and (max-width: 1300px) {
  //   .line{
  //     display: none;
  //   }
  // }

